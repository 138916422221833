import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// 正式答题单选
		list: [],
		// 多选
		list1: [],
		// 判断
		list2: [],
		// 答题用时
		answerTime: '00:00:00',
		// 答题历史记录
		answerHistory: {}
	},
	mutations: {
		//把题目拆分成单选，多选，判断三个数组
		SET_LIST(state, data) {
			state.list = [];
			state.list1 = [];
			state.list2 = [];
			data.formalAnswer.forEach((item, index) => {
				if (item.typedata == 1) {
					state.list.push(item)
				} else if (item.typedata == 2) {
					state.list1.push(item)
				} else if (item.typedata == 3) {
					state.list2.push(item)
				}
			})
			/* console.log(state.getlist, "答题题目单选")
			console.log(state.getlist1, "答题题目多选")
			console.log(state.getlist2, "答题题目判断") */
		},
		// 获取答题用时
		SET_ANSWERTIME(state, data) {
			// state.answerTime = data.answerTime
			// 总时间
			let count = 3600;
			// 截取时间
			let timeArr = data.answerTime.split(':');
			// 获取分钟的秒数
			let m = timeArr[0] * 60;
			// 获取秒
			let s = timeArr[1];
			// 答题所用秒数
			let time = count - (m + s)
			if (time == 3600) {
				state.answerTime = '时间已用完'
			} else {
				// 转分钟
				let mm = parseInt(time / 60 % 60)
				// 转秒
				let ss = parseInt(time % 60)
				if (mm === 0) {
					state.answerTime = ss + '秒'
				} else {
					state.answerTime = (mm < 10 ? '0' + mm : mm) + '分' + (ss < 10 ? '0' + ss : ss) + '秒'
				}
				console.log(mm + ':' + ss, "答题时间")
			}
		},
		// 获取正式答题历史记录
		SET_ANSWERTIMEHISTORY(state, data) {
			console.log(data.history, "vuex记录")
			let list = [],
				list1 = [],
				list2 = [];
			data.history.forEach((item, index) => {
				if (item.typedata == 1) {
					list.push(item)
				} else if (item.typedata == 2) {
					list1.push(item)
				} else if (item.typedata == 3) {
					list2.push(item)
				}
			})
			state.answerHistory = {
				list: list,
				list1: list1,
				list2: list2
			}
		}
	},
	actions: {
		changS({
			commit
		}, data) {
			commit('SET_LIST', data)
		},
		changeTime({
			commit
		}, data) {
			commit('SET_ANSWERTIME', data)
		},
		changeHistory({
			commit
		}, data) {
			commit('SET_ANSWERTIMEHISTORY', data)
		}
	},
	modules: {

	}
})

import axios from 'axios'
import qs from 'qs'
import {
	Toast
} from 'vant'
import 'vant/lib/index.css'
import router from '../router'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
/* let baseURL = '';
if (process.env.NODE_ENV === 'production') {
	console.log('生产环境')
	baseURL: 'https://api.ditanzhutikepu.kc87.com';
} else {
	baseURL,
	console.log("开发环境")
} */

const instance = axios.create({
	baseURL: 'https://api.aizhutidati.kc87.com',
	// baseURL,
	timeout: 500000
})

instance.interceptors.request.use(config => {
	console.log(config.url,'config')
	if (localStorage.getItem('token') && config.url.indexOf('rankInfo')==-1) {
		config.headers.token = localStorage.getItem('token')
	}
	if (config.method === 'post') {
		//config.data = qs.stringify(config.data)
	}
	return config
}, error => {
	return Promise.reject(error)
})

instance.interceptors.response.use(response => {
	return response
}, err => {
	console.log(err)
	console.log(err.response)
	if (err && err.response) {
		switch (err.response.status) {
			case 400:
				err.message = '请求错误(400)'
				break
			case 401:
				err.message = '未授权，请重新登录(401)'
				break
			case 402:
				err.message = '未关注'
				break
			case 403:
				err.message = '拒绝访问(403)'
				break
			case 404:
				err.message = '请求出错(404)'
				break
			case 408:
				err.message = '请求超时(408)'
				break
			case 501:
				err.message = '服务未实现(501)'
				break
			case 502:
				err.message = '网络错误(502)'
				break
			case 503:
				err.message = '服务不可用(503)'
				break;
			case 504:
				err.message = '网络超时(504)'
				break
			case 505:
				err.message = 'HTTP版本不受支持(505)'
				break
			default:
				err.message = `连接出错(${err.response.status})!`
		}
	} else {
		err.message = '连接服务器失败!'
	}

	Toast({
		message: err.message,
		mask: true,
		duration: 2000,
		onClose: () => {
			if (err.response.status == 402 || err.response.status == 401) {
				localStorage.removeItem('token')
				location.href = '/'
			}
		}
	})

	return Promise.reject(err)
})

export default instance

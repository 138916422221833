import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import '@/assets/fonts/font.css'
import 'amfe-flexible'
import _ from 'lodash'
Vue.prototype._ = _
import request from './utils/request'
Vue.prototype.$http = request
Vue.config.productionTip = false

import {
  Button,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Cascader,
  Popup,
  Area,
  Overlay,
  Toast,
  Icon,
  Picker,
  Col,
  Row,
  Sticky,
  Dialog,
  Uploader
} from 'vant'

[
  Button,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Cascader,
  Popup,
  Area,
  Overlay,
  Toast,
  Icon,
  Picker,
  Col,
  Row,
  Sticky,
  Dialog,
  Uploader
].forEach(e => {
  Vue.use(e)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Index',
		meta: {
			title: '新能源科普知识竞赛'
		},
		component: () => import('../views/index')
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '新能源科普知识竞赛'
		},
		component: () => import('../views/login')
	},
	{
		path: '/chooseIdentity',
		name: 'chooseIdentity',
		meta: {
			title: '请选择您的身份'
		},
		component: () => import('../views/chooseIdentity')
	},
	{
		path: '/index',
		name: 'dati',
		meta: {
			title: '新能源科普知识竞赛'
		},
		component: () => import('../views/dati')
	},
	{
		path: '/hosdati',
		name: 'hosdati',
		meta: {
			title: '历史答题记录'
		},
		component: () => import('../views/hosdati')
	},
	{
		path: '/info',
		name: 'info',
		meta: {
			title: '个人信息'
		},
		component: () => import('../views/info')
	},
	{
		path: '/paihang',
		name: 'paihang',
		meta: {
			title: 'top风云榜'
		},
		component: () => import('../views/paihang')
	},
	{
		path: '/search',
		name: 'search',
		meta: {
			title: '成绩查询'
		},
		component: () => import('../views/search')
	},
	{
		path: '/cj',
		name: 'cj',
		meta: {
			title: '成绩'
		},
		component: () => import('../views/cj')
	},
	{
		path: '/notice',
		name: 'notice',
		meta: {
			title: '新能源科普知识竞赛'
		},
		component: () => import('../views/notice')
	},
	{
		path: '/awards',
		name: 'awards',
		meta: {
			title: '新能源科普知识竞赛'
		},
		component: () => import('../views/awards')
	}
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

export default router
